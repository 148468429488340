// msal
export const MSAL_IDTOKEN_KEY = "msal.idtoken";

// app
export const DEBOUNCE_INPUT_DELAY = 500;
export const DEBOUNCE_FUNCTION_DELAY = 200;
export const DEBOUNCE_AUTOCOMPLETE_SUGGESTIONS_DELAY = 350;
export const DEBOUNCE_TABLE_FILTER_DELAY = 400;

export const THROTTLE_UPLAD_PROGRESS_DELAY = 500;

export const DEFAULT_AUTOCOMPLETE_FETCH_LIMIT = 8;

export const DEFAULT_FILE_LIST_MOVE_AMOUNT = 1;

export const MOVE_FILE_UP = true;
export const MOVE_FILE_DOWN = false;

// statuses
export const STATUS_QUEUED = "QUEUED";
export const STATUS_IN_PROGRESS = "IN_PROGRESS";
export const STATUS_SUCCESS = "SUCCESS";
export const STATUS_FAILED = "FAILED";
export const STATUS_WARNING = "WARNING";

// routes
export const ROUTE_TO_LANDING_PAGE = "/";
export const ROUTE_TO_LOGIN = "/login";
export const ROUTE_TO_DASHBOARD = "/dashboard";
export const ROUTE_TO_DASHBOARD_ERROR_PAGE = "/dashboard/error";

// snackbar messages
export const FILE_SIZE_TOO_LARGE_MESSAGE = "File size too large!";
export const UNABLE_TO_FIND_CASESUBMISSION =
  "Unable to find specified case submission!";
export const UNABLE_TO_FIND_DATABASE_MESSAGE =
  "Could not find specified database";
