export const caseSubmissionValidation = (currentFormErrors, field, value) => {
  // validation
  let formErrors = { ...currentFormErrors };
  switch (field) {
    case "case":
      // check if valid value
      if (!value || value === null || !value.name) {
        formErrors[field] = "Invalid or missing field";
        break;
      }

      formErrors[field] = null;
      break;
    case "project":
      // check if valid value
      if (!value || value === null || !value.id || !value.key || !value.name) {
        formErrors[field] = "Invalid or missing project";
        break;
      }

      formErrors[field] = null;
      break;
    default:
  }

  return formErrors;
};
