/* GLOBAL STATES */
export const initialGlobalState = {
  isSnackbarOpen: false,
  snackbarMessage: "",
  fixedHeader: true,
};

/* AUTH STATES */
export const initialAuthState = {
  user: {},
  accessLevel: 0,
  token: null,
  isErr: false,
  err: null,
  isAuthenticated: false,
  requestedPage: null,
  isInitialRequestedPageStored: false,
};

export const defaultCaseSubmissionFormState = () => ({
  project: null,
  case: null,
  files: [],
});

export const defaultFormErrorsState = () => ({});

export const initialCaseSubmissionState = {
  // submit in progress related
  submitInProgress: false,
  queuedFileUploads: 0,
  currentFileUploadProgress: 0,
  // project autocomplete
  projectAutocompleteOptions: [],
  isLoadingProjects: false,
  // case autocomplete
  caseAutocompleteOptions: [],
  isLoadingCases: false,
  // form
  loadingForm: true,
  checkingOverwriteOrForbidden: false,
  overwriteDialogOpen: false,
  submitForbiddenDialogOpen: false,
  formErrors: defaultFormErrorsState(),
  form: defaultCaseSubmissionFormState(),
};
