import * as actions from "./actionTypes";

export const fetchDummyQuery = () => {
  return { type: actions.FETCH_DUMMY_QUERY_SAGA, payload: {} };
};

export const changeCaseSubmissionFormField = (field, value) => {
  return {
    type: actions.CHANGE_CASESUBMISSION_FORM_DATA,
    payload: { field, value },
  };
};

export const changeCaseSubmissionAutocompleteField = (
  field,
  selectedOption
) => {
  return {
    type: actions.CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD,
    payload: { field, selectedOption },
  };
};

export const onAutocompleteInputChange = (field, value) => {
  return {
    type: actions.SEARCH_FOR_PROJECTS_SAGA,
    payload: { field, value },
  };
};

export const onAutocompleteCaseInputChange = (field, value) => {
  return {
    type: actions.SEARCH_FOR_CASES_SAGA,
    payload: { field, value },
  };
};

export const getCaseSubmission = (payload) => {
  return { type: actions.FETCH_CASESUBMISSION_SAGA, payload };
};

export const resetLoadingFormStatus = () => {
  return { type: actions.RESET_LOADING_FORM_STATUS, payload: null };
};

export const loadNewCaseSubmissionForm = () => {
  return { type: actions.LOAD_NEW_CASESUBMISSION_FORM, payload: null };
};

export const startUploadAndFormSubmit = () => {
  return { type: actions.START_UPLOAD_AND_SUBMIT_SAGA, payload: null };
};

export const checkForOverwrites = () => {
  return { type: actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA, payload: null };
};

export const closeOverwriteDialog = () => {
  return { type: actions.CLOSE_OVERWRITE_OR_FORBIDDEN_DIALOG, payload: null };
};

export const queueFilesForUpload = (files) => {
  return { type: actions.QUEUE_FILES_FOR_UPLOAD, payload: { files } };
};

export const deleteFileForUpload = (index) => {
  return { type: actions.DELETE_FILE_FROM_LIST, payload: { index } };
};

export const uploadFileProgress = (blobContainer, blobName, progress) => {
  return {
    type: actions.UPLOAD_FILE_PROGRESS,
    payload: { blobContainer, blobName, progress },
  };
};

export const reorderFileList = (moveUp = true, index) => {
  return { type: actions.REORDER_FILE_LIST, payload: { moveUp, index } };
};
