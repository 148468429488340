import * as actions from "../actions/actionTypes";

import {
  initialCaseSubmissionState,
  defaultFormErrorsState,
  defaultCaseSubmissionFormState,
} from "../store/initialState";

import { caseSubmissionValidation } from "../store/storeFunctions";

import { getNestedObject } from "../utils/functions";

import { DEFAULT_FILE_LIST_MOVE_AMOUNT } from "../utils/constants";
import {
  CONFLICTING_SUBMIT_DETECTED,
  FORBIDDEN_SUBMIT_DETECTED,
} from "../utils/errorConstants";

const caseSubmissionReducer = (state = initialCaseSubmissionState, action) => {
  let formErrors = null;
  let queuedFileUploads = null;
  let progress = null;
  let files = null;

  switch (action.type) {
    case actions.CHANGE_CASESUBMISSION_FORM_DATA:
      let form = { ...state.form };

      switch (action.payload.field) {
        case "caseName":
          form.case[action.payload.field] = action.payload.value;
          break;
        default:
          form[action.payload.field] = action.payload.value;
      }

      formErrors = caseSubmissionValidation(
        state.formErrors,
        action.payload.field,
        action.payload.value
      );

      return { ...state, formErrors, form };
    case actions.CHANGE_CASESUBMISSION_AUTOCOMPLETE_FIELD:
      // TODO: consolidate this into CHANGE_CASESUBMISSION_FORM_DATA action?
      formErrors = caseSubmissionValidation(
        state.formErrors,
        action.payload.field,
        action.payload.selectedOption
      );

      return {
        ...state,
        formErrors,
        form: {
          ...state.form,
          [action.payload.field]: action.payload.selectedOption,
        },
      };
    case actions.RESET_LOADING_FORM_STATUS:
      return { ...state, loadingForm: true };
    case actions.LOAD_NEW_CASESUBMISSION_FORM:
      return {
        ...state,
        formErrors: defaultFormErrorsState(),
        form: defaultCaseSubmissionFormState(),
        loadingForm: false,
      };
    // getCaseSubmission (when viewing/fetching a single submission)
    case actions.FETCH_CASESUBMISSION_SAGA_SUCCESS:
      const caseSubmissionData = action.payload.data;

      return {
        ...state,
        form: caseSubmissionData,
        formErrors: defaultFormErrorsState(),
        loadingForm: false,
      };
    case actions.FETCH_CASESUBMISSION_SAGA_FAILURE:
      return { ...state };
    // getProjects
    case actions.SEARCH_FOR_PROJECTS_SAGA:
      return { ...state, isLoadingProjects: true };
    case actions.SEARCH_FOR_PROJECTS_SAGA_SUCCESS:
      return {
        ...state,
        projectAutocompleteOptions: action.payload.data,
        isLoadingProjects: false,
      };
    case actions.SEARCH_FOR_PROJECTS_SAGA_FAILURE:
      return { ...state, isLoadingProjects: false };
    // getCases
    case actions.SEARCH_FOR_CASES_SAGA:
      return { ...state, isLoadingCases: true };
    case actions.SEARCH_FOR_CASES_SAGA_SUCCESS:
      return {
        ...state,
        caseAutocompleteOptions: action.payload.data,
        isLoadingCases: false,
      };
    case actions.SEARCH_FOR_CASES_SAGA_FAILURE:
      return { ...state, isLoadingCases: false };
    /**
     * OVERWRITE CHECKS
     */
    case actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA:
      return { ...state, checkingOverwriteOrForbidden: true };
    case actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA_SUCCESS:
      // success - no conflict found
      // upload saga started from CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA saga
      return { ...state, checkingOverwriteOrForbidden: false };
    case actions.CHECK_FOR_OVERWRITE_OR_FORBIDDEN_SAGA_FAILURE:
      // failure - conflict found
      let overwriteDialogOpen = state.overwriteDialogOpen;
      let submitForbiddenDialogOpen = state.submitForbiddenDialogOpen;

      const err = getNestedObject(action, ["payload", "err"]) || null;

      if (err && err === CONFLICTING_SUBMIT_DETECTED) {
        overwriteDialogOpen = true;
        submitForbiddenDialogOpen = false;
      }

      if (err && err === FORBIDDEN_SUBMIT_DETECTED) {
        overwriteDialogOpen = false;
        submitForbiddenDialogOpen = true;
      }

      return {
        ...state,
        checkingOverwriteOrForbidden: false,
        overwriteDialogOpen,
        submitForbiddenDialogOpen,
      };
    /**
     * CLOSE OVERWRITE DIALOG
     */
    case actions.CLOSE_OVERWRITE_OR_FORBIDDEN_DIALOG:
      return {
        ...state,
        overwriteDialogOpen: false,
        submitForbiddenDialogOpen: false,
      };
    /**
     *
     * UPLOAD FILES AND SUBMIT FORM
     *
     *
     */
    case actions.START_UPLOAD_AND_SUBMIT_SAGA:
      return {
        ...state,
        queuedFileUploads: state.form.files.length,
        currentFileUploadProgress: 0,
        submitInProgress: true,
        overwriteDialogOpen: false,
        submitForbiddenDialogOpen: false,
      };
    case actions.START_UPLOAD_AND_SUBMIT_SAGA_SUCCESS:
      return { ...state, submitInProgress: false };
    case actions.START_UPLOAD_AND_SUBMIT_SAGA_FAILURE:
      return { ...state, submitInProgress: false };
    /**
     *
     *  FILE ACTIONS REDUCER
     *
     */
    case actions.UPLOAD_FILE_PROGRESS:
      // blobContainer and blobName in payload currently unused
      // potentially useful for future feature expansion
      progress = action.payload.progress * 100;

      return {
        ...state,
        currentFileUploadProgress: progress,
      };
    case actions.UPLOAD_FILE_DONE:
      queuedFileUploads = state.queuedFileUploads - 1;

      return {
        ...state,
        queuedFileUploads,
      };
    case actions.QUEUE_FILES_FOR_UPLOAD:
      /**
       * legacy - use this if multiple files can be uploaded
       */
      // files = [...state.form.files];

      // adding only one file is supported, so we can overwrite previous
      files = [];

      formErrors = { ...state.formErrors };

      for (let file of action.payload.files) {
        // file is File/Blob object!
        files.push({
          blobObject: file,
          key: "" + files.length + Date.now().toString(),
        });
      }

      // at least one file was added, so remove that error
      formErrors["files"] = null;

      return {
        ...state,
        formErrors,
        form: { ...state.form, files },
      };
    case actions.DELETE_FILE_FROM_LIST:
      files = [...state.form.files];
      formErrors = { ...state.formErrors };

      files.splice(action.payload.index, 1);

      if (!files || files.length === 0) {
        formErrors["files"] = "Missing files!";
      }

      return {
        ...state,
        formErrors,
        form: { ...state.form, files },
      };
    case actions.REORDER_FILE_LIST:
      files = [...state.form.files];

      const tmpFile = files[action.payload.index];

      // move up
      if (action.payload.moveUp && action.payload.index > 0) {
        files[action.payload.index] =
          files[action.payload.index - DEFAULT_FILE_LIST_MOVE_AMOUNT];

        files[action.payload.index - DEFAULT_FILE_LIST_MOVE_AMOUNT] = tmpFile;
      }

      // move down
      if (!action.payload.moveUp && action.payload.index < files.length - 1) {
        files[action.payload.index] =
          files[action.payload.index + DEFAULT_FILE_LIST_MOVE_AMOUNT];
        files[action.payload.index + DEFAULT_FILE_LIST_MOVE_AMOUNT] = tmpFile;
      }

      return {
        ...state,
        form: { ...state.form, files },
      };
    default:
      return state;
  }
};

export default caseSubmissionReducer;
